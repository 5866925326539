/* LoadingScreen.css */
.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #7ec8bb url('../images/icons/loadingv3.gif') no-repeat center center;
    z-index: 9999;
}
