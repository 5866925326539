.projectsContainer{
    color: #f3ffff;
    height: 100%;
}

.projects_container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh; /* Adjust based on your needs */
}
.inner-container{
    display: grid;
    grid-template-columns: repeat(3, auto); /* Adjust based on the size and number of spheres */
    gap: 20px; /* Adjust the gap size as needed */
}
/*.sphere {
    width: 50px; /* Sphere size 
    height: 50px;
    border-radius: 50%;
    background-color: #E27D60; /* Sphere color 
    /* Additional styling as needed 
}*/
.portfolio-instructions {
    position: fixed; /* Or absolute, depending on your layout needs */
    top: 0;
    right: 0;
    margin: 20px; /* Adjust as needed */
    background-color: #E27D60; /* Or any color you prefer */
    padding: 10px;
    border-radius: 5px; /* Optional, for styled corners */
    box-shadow: 0 2px 4px rgba(0,0,0,0.2); /* Optional, for a subtle shadow */
}

.sphere-container {
    position: absolute;
    display: grid;
    grid-template-columns: repeat(3, 100px); /* Adjust based on the number of spheres per row */
    gap: 50px; /* Adjust the gap size */
}

.sphere {
    width: 110px; /* Sphere size */
    height: 110px;
    background-color: #E27D60; /* Sphere color */
    
    border-radius: 50%;
    box-shadow: 0 15px 15px rgba(0, 0, 0, 0.2); /* Adjust as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Ensures no part of the image spills outside the sphere */
}

.sphere-image {
    width: auto; /* Adjust based on your needs */
    height: 100%; /* Make the image fill the sphere */
    object-fit: cover; /* Cover the sphere area nicely */
}
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Dimmed background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050; /* Ensure it's above other content */
}

.modal-content {
    background-color: #7ec8bb;
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two equal columns */
    gap: 20px; /* Space between columns */
    width: 700px; /* Set width */
    height: 500px; /* Set height */
    padding: 20px;
    border-radius: 5px;
    position: relative; /* For positioning the close button */
    box-shadow: 0 4px 6px rgba(0,0,0,0.2); /* Optional: adds a subtle shadow */
}
.project-info {
    /* Additional styling for the project info section */
    padding: 20px; /* Adds padding around the text */
    max-height: 400px; /* Adjust based on your modal size */
    overflow-y: auto; /* Enables vertical scrolling if content overflows */
    padding-right: 10px; /* Optional, for better visual spacing */
    scrollbar-width: thin;
    scrollbar-color: #f5b6ca #f3ffff;
}
.project-info::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
  }
.project-info::-webkit-scrollbar-track {
    background: #f3ffff; /* Color of the track */
}
  
.project-info::-webkit-scrollbar-thumb {
    background-color: #C38D9E; /* Color of the scrollbar thumb */
    border-radius: 20px; /* Rounded corners on the thumb */
}
.project-info h2 {
    margin-top: 0;
    font-weight: bold;
    color: #E27D60; /* Title color */
    font-size: 23px; /* Adjust title font size as needed */
    margin-bottom: 10px; /* Space between the title and the description */
}

.project-info ul {
    padding-left: 20px; /* Adjust based on your design */
}

.project-info li {
    margin-bottom: 10px; /* Adds space between bullet points */
    font-size: 16px;
    color: #e4f3f3;
    font-weight: bold;
}
.project-image img {
    width: 100%; /* Ensure the image fits the grid area */
    height: auto; /* Maintain aspect ratio */
    border-radius: 5px; /* Optional: for styled image corners */
}

.close-button {
    position: absolute;
    top: -30px; /* Adjust as needed to float above the modal */
    right: 10px; /* Adjust based on your design preferences */
    background-color: transparent;
    color: #f3ffff;
    border: none;
    cursor: pointer;
    font-size: 16px;
    padding: 10px;
    z-index: 1051; /* Ensure it's above the modal content */
}

.close-button:hover {
    color: #d6e6e6; /* Change color on hover */
}

@media (max-width: 900px) {
    .portfolio-grids{
        display: grid;
        grid-template-areas: 
        "port-left port-left" 
        "port-right port-right";
        width: 100%;
        height: 300px;
        justify-content: center;
        margin-bottom: 30px;
        grid-gap: 20px;
    }
    .portfolio-instructions {
        grid-area: port-left;
        justify-content: center;
        align-items: center;
        position: relative;
        width: max(90%, 300px);
        background-color: #E27D60; /* Or any color you prefer */
        padding: 20px;
        border-radius: 5px; /* Optional, for styled corners */
        box-shadow: 0 2px 4px rgba(0,0,0,0.2); /* Optional, for a subtle shadow */
        margin: auto;
        top: 30px;
    }
    .projects_container{
        grid-area: port-right;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60vh; /* Adjust based on your needs */
    }
}

@media (max-width: 400px) {
    .portfolio-grids{
        display: grid;
        grid-template-areas: 
        "port-left port-left" 
        "port-right port-right";
        width: 100%;
        height: 300px;
        justify-content: center;
        margin-bottom: 30px;
        grid-gap: 20px;
    }
    .portfolio-instructions {
        grid-area: port-left;
        justify-content: center;
        align-items: center;
        position: relative;
        width: max(50%, 300px);
        background-color: #E27D60; /* Or any color you prefer */
        padding: 30px;
        border-radius: 5px; /* Optional, for styled corners */
        box-shadow: 0 2px 4px rgba(0,0,0,0.2); /* Optional, for a subtle shadow */
        margin: auto;
        top: 30px;
    }
    .projects_container{
        grid-area: port-right;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 45vh; /* Adjust based on your needs */
    }
    .sphere-container {
        gap: 25px; /* Adjust the gap size */
    }
    .modal-content{
        background-color: #7ec8bb;
        display: grid;
        grid-template-areas:
        "project-image project-image"
        "project-info project-info";
        gap: 20px; /* Space between columns */
        width: 700px; /* Set width */
        height: 500px; /* Set height */
        padding: 20px;
        border-radius: 5px;
        position: relative; /* For positioning the close button */
        box-shadow: 0 4px 6px rgba(0,0,0,0.2); /* Optional: adds a subtle shadow */
    }
    .project-info {
        grid-area: project-info;
        /* Additional styling for the project info section */
        padding: 20px; /* Adds padding around the text */
        max-height: 400px; /* Adjust based on your modal size */
        overflow-y: auto; /* Enables vertical scrolling if content overflows */
        padding-right: 10px; /* Optional, for better visual spacing */
        scrollbar-width: thin;
        scrollbar-color: #f5b6ca #f3ffff;
        margin-top: -70px;
    }
    .project-image {
        grid-area: project-image;
        display: flex;
        justify-content: center;
        /* Additional styling for the project image section */
        padding: 20px; /* Adds padding around the image */
    }
    .project-image img {
        width: 60%; /* Ensure the image fits the grid area */
        height: auto; /* Maintain aspect ratio */
        border-radius: 5px; /* Optional: for styled image corners */
        margin-top: -60px;
    }
    
}