.navbar {
    display: grid;
    grid-template-columns: min-content 1fr min-content;
    align-items: center;
    padding: 20px;
}

.logo-container {
    /* Additional styling for the logo container if needed */
    padding-left: 35px;
}

.nav-sphere {
    width: 80px; /* Adjust the size as needed */
    height: 80px;
    background-color: #E27D60;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid #f3ffff; /* Adjust border thickness as needed */
}
.logo {
    height: 50px; /* Adjust as per your logo's size */
}

.buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-button {
    background-color: #E27D60; /* or any color you prefer */
    color: #f3ffff;
    border: none;
    padding: 10px 20px;
    margin: 5px;
    border-radius: 5px; /* Optional for rounded corners */
    cursor: pointer;
    font-size: 16px;
    text-decoration: none;
    font-weight: bold;

    transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.nav-button:hover {
    background-color: #CC6649; /* Darker shade for hover */
}

.active-link {
    border-top: 7px solid #f3ffff;
    border-bottom: 7px solid #f3ffff; /* Adjust color and thickness as needed */
    /* Additional styling if needed */
  }

.vertical-nav .buttons-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 200px; /* Adjust as needed */
    gap: 20px; /* Adjust this value to control the gap size */
}

.fixed-arrow {
    position: fixed;  /* Fixed position relative to the viewport */
    right: 20px;      /* 20px from the right edge of the viewport */
    bottom: 20px;     /* 20px from the bottom edge of the viewport */
    width: 120px;      /* Adjust the size as needed */
    height: auto;     /* Maintain aspect ratio */
    z-index: 1;    /* Ensure it's above other elements */
    
    /* New styles for the white circle border */
    border: 2px solid #f3ffff; /* Adjust the border thickness as needed */
    border-radius: 50%; /* Makes the border rounded */
    padding: 10px; /* Adds some space between the image and the border */

    background-color: #7ec8bb;
    
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Optional: adds a shadow for depth */
}
.arrow-container{
    position: fixed;
    right: 20px;
    bottom: 20px;
    display: flex;
    flex-direction: column;
}

.fixed-arrow:hover {
    transition: all 0.3s ease; /* Smooth transition for hover effect */
    background-color: hsl(171, 40%, 53%); /* 10% darker */
}

/* Always hide the hamburger menu on larger screens */
.hamburger {
    display: none;
  }


@media (max-width: 900px) {
    .navbar {
        display: flex;
        justify-content: space-between;
        padding: 20px;
    }
    .vertical-nav .buttons-container{
        display: none;
    }
    .arrow-container {
        display: none; /* Hide the nav sphere on smaller screens */
    }

    .logo-container {
        display: flex;
        align-items: center;
    }

    .logo {
        height: 40px; /* Adjust as per your logo's size */
    }

    .buttons-container {
        display: none; /* Hide the buttons on smaller screens */
    }

    .hamburger {
        display: block; /* Show the hamburger menu on smaller screens */
        flex-direction: column;
        justify-content: space-around;
        margin-bottom: 20px;
        width: 30px; /* Adjust width of hamburger */
        height: 25px; /* Adjust height of hamburger */
        cursor: pointer;
        z-index: 100; /* Ensure it's above other content */
    }
    .hamburger div {
        width: 30px;
        height: 3px;
        background-color: #f3ffff;
        margin: 6px 0;
        transition: 0.4s;
        margin-bottom: 7px;
    }
    .mobile-menu {
        position: fixed;
        top: 90px; /* Adjust based on the hamburger's size and your layout */
        right: 12px;
        display: flex;
        flex-direction: column;
        align-items: flex-start; /* Aligns the text to the left */
        padding: 10px; /* Add some padding around the links */
        background-color: #7ec8bb;
        box-shadow: -2px 0px 5px rgba(0,0,0,0.2); /* Optional: adds shadow to the menu */
        z-index: 99; /* Ensure menu is below the hamburger icon but above other content */
    }
    .nav-button-mobile {
        background-color: #E27D60; /* or any color you prefer */
        color: #f3ffff;
        border: none;
        padding: 10px 20px;
        margin: 5px;
        border-radius: 5px; /* Optional for rounded corners */
        cursor: pointer;
        font-size: 16px;
        text-decoration: none;
        font-weight: bold;
        /*display: block;  Ensures the links take up the full width */
    
        transition: background-color 0.3s; /* Smooth transition for hover effect */
    }
    .active-link {
        border-top: 7px solid #f3ffff;
        border-bottom: 7px solid #f3ffff; /* Adjust color and thickness as needed */
        /* Additional styling if needed */
      }
}

@media (max-width: 400px) {
    .navbar {
        display: flex;
        justify-content: space-between;
        padding: 20px;
    }
    .arrow-container {
        display: none; /* Hide the nav sphere on smaller screens */
    }

    .logo-container {
        display: flex;
        align-items: center;
    }

    .logo {
        height: 40px; /* Adjust as per your logo's size */
    }

    .buttons-container {
        display: none; /* Hide the buttons on smaller screens */
    }

    .hamburger {
        display: block; /* Show the hamburger menu on smaller screens */
        flex-direction: column;
        justify-content: space-around;
        margin-bottom: 20px;
        width: 30px; /* Adjust width of hamburger */
        height: 25px; /* Adjust height of hamburger */
        cursor: pointer;
        z-index: 100; /* Ensure it's above other content */
    }
    .hamburger div {
        width: 30px;
        height: 3px;
        background-color: #f3ffff;
        margin: 6px 0;
        transition: 0.4s;
        margin-bottom: 7px;
    }
    .mobile-menu {
        position: fixed;
        top: 90px; /* Adjust based on the hamburger's size and your layout */
        right: 12px;
        display: flex;
        flex-direction: column;
        align-items: flex-start; /* Aligns the text to the left */
        padding: 10px; /* Add some padding around the links */
        background-color: #7ec8bb;
        box-shadow: -2px 0px 5px rgba(0,0,0,0.2); /* Optional: adds shadow to the menu */
        z-index: 99; /* Ensure menu is below the hamburger icon but above other content */
    }
    .nav-button-mobile {
        background-color: #E27D60; /* or any color you prefer */
        color: #f3ffff;
        border: none;
        padding: 10px 20px;
        margin: 5px;
        border-radius: 5px; /* Optional for rounded corners */
        cursor: pointer;
        font-size: 16px;
        text-decoration: none;
        font-weight: bold;
        /*display: block;  Ensures the links take up the full width */
    
        transition: background-color 0.3s; /* Smooth transition for hover effect */
    }
    .active-link {
        border-top: 7px solid #f3ffff;
        border-bottom: 7px solid #f3ffff; /* Adjust color and thickness as needed */
        /* Additional styling if needed */
      }
      .nav-sphere{
        right: 30px;
        position: relative;
      }
}