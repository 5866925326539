.contactContainer{
    height: 100%;
}

.contact-form{
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    height: 50vh; /* Adjust the height as needed */
}
.form{
    background-color: #f3ffff;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    max-width: 600px;
    height: 600px;
    margin: auto;
}
.form div {
    margin-bottom: 10px; /* Adds space between form fields */
}
.form label {
    display: block; /* Makes the label a block element, forcing it onto its own line */
    margin-bottom: 5px; /* Adds a little space between the label and the input */
    font-weight: bold; /* Makes the label text bold */
}

.form input[type="text"],
.form input[type="tel"],
.form input[type="email"],
.form textarea {
    width: 50%; /* Makes form inputs take up full container width */
    padding: 10px; /* Adds some padding inside the inputs */
    border-radius: 5px; /* Slightly rounds the corners of the inputs */
    border: 1px solid #ccc; /* Gives a light border to the inputs */
}
.form .form-row{
    display: flex;
    justify-content: space-between;
}
.form .form-group{
    flex: 1;
    margin-right: 10px;
}
.form .form-group:last-child {
    margin-right: 0; /* Removes margin from the last group */
}

.form button {
    background-color: #E27D60; /* Example button color */
    color: #f3ffff; /* Button text color */
    padding: 10px 20px; /* Button padding */
    border: none; /* Removes border */
    border-radius: 5px; /* Rounds button corners */
    cursor: pointer; /* Changes cursor to pointer on hover */
    font-size: 16px;
    text-decoration: none;
    font-weight: bold;
}

.form button:hover {
    background-color: #CC6649; /* Darker button color on hover */
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
textarea:focus {
    border-color: #CC6649; /* Changes border color on focus */
    outline: none; /* Removes the default focus outline */
    box-shadow: 0 0 5px rgba(76, 175, 80, 0.5); /* Adds a glow effect on focus */
}

input[type="text"]::placeholder,
input[type="email"]::placeholder,
input[type="tel"]::placeholder,
textarea::placeholder {
    color: #a9a9a9; /* Sets the color of the placeholder text */
    font-style: italic; /* Optional: makes the placeholder text italic */
}

.message-input{
    width: 200px;
}
.form p{
    margin-bottom: 50px;
    font-size: 16px;
}

@media (max-width: 768px) {
  .form{
    width: 100%;
    max-width: 400px;
    height: auto;
  }
  html, body{
    min-height: 100vh;
    overflow-y: scroll;
  }
}

@media (max-width: 400px) {
    .form{
        width: 100%;
        max-width: 300px;
        height: auto;
      }
}