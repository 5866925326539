.homeContainer{
    color: #f3ffff ;
    height: 500px;
    
}
.home-title-mobile-1{
    display: none;
}
.home-title-mobile-2{
    display: none;
}
.home-section-grids{
    display: grid;
    grid-template-areas: 
    "home-left home-left home-right home-right";
    width: 100%;
    height: 300px;
    justify-content: center;
    margin-bottom: 30px;
    grid-gap: 20px;
    
}
.home-left-grid{
    grid-area: home-left;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 500px;
    padding: 20px;
}
.home-right-grid{
    grid-area: home-right;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 500px;
    
}
.welcome_message_container{
    justify-content: center;
    padding-top: 70px;
    margin-left: 60px;
}
.working{
    width: 340px;
    height: auto;
}
.welcome_message_container p{
    font-size: 20px;
    margin-top: 20px;
    font-weight: 500;
}

@media (max-width: 900px) {
    .home-section-grids{
        display: grid;
        grid-template-areas: 
        "home-top home-top"
        "home-left home-left"
        "home-right home-right";
        width: 100%;
        height: auto;
        justify-content: center;
        margin-bottom: 30px;
        grid-gap: 20px;
        
    }
    .home-top-grid{
        grid-area: home-top;
        justify-content: center;
        align-items: center;
        width: 500px;
        padding: 20px;
    }
    .home-left-grid{
        grid-area: home-right;
        justify-content: center;
        align-items: center;
        height: auto;
        width: 500px;
        margin-top: -250px;
        padding: 20px;
    }
    .home-right-grid{
        grid-area: home-left;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -100px;
        height: auto;
        width: 500px;
    }
    .home-title{
        display: none;
    }
    .home-title-mobile-1{
        display: block;
        text-align: center;
        z-index: 0;
    }
    .welcome_message_container{
        justify-content: center;
        padding-top: 70px;
        margin-left: 55px;
        width: 400px;
    }
    .working{
        width: 200px;
        height: auto;
    }
    .welcome_message_container p{
        font-size: 18px;
        margin-top: 20px;
        font-weight: 500;
    }
}

@media (max-width: 400px) {
    .home-section-grids{
        display: grid;
        grid-template-areas: 
        "home-top home-top"
        "home-left home-left"
        "home-right home-right";
        width: 100%;
        height: 300px;
        justify-content: center;
        margin-bottom: 30px;
        grid-gap: 20px;
        
    }
    .home-top-grid{
        grid-area: home-top;
        justify-content: center;
        align-items: center;
        width: 200px;
        padding: 20px;
    }
    .home-left-grid{
        grid-area: home-right;
        display: flex;
        justify-content: center;
        align-items: center;
        height: auto;
        width: 200px;
        margin-top: -220px;
        padding: 20px;
        
    }
    .home-right-grid{
        grid-area: home-left;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -105px;
        height: auto;
        width: 300px;
    }
    .home-title{
        display: none;
    }
    .home-title-mobile-1{
        display: flex;
        justify-content: center;
        text-align: center;
        z-index: 0;
    }
    .welcome_message_container{
        justify-content: center;
        padding-top: 70px;
        margin-left: 55px;
        width: 300px;
    }
    .working{
        width: 200px;
        height: auto;
    }
    .welcome_message_container p{
        font-size: 18px;
        margin-top: 20px;
        font-weight: 500;
        width: 300px;
    }
}
/* Phones (portrait and landscape) ----------- */
@media only screen and (max-width: 768px) {
    /* Styles */
  }
  
/* Tablets (portrait and landscape) ----------- */
@media only screen and (min-width: 600px) and (max-width: 900px) {
    /* Styles */
}
  
/* Desktops and laptops ----------- */
@media only screen and (min-width: 900px) {
    /* Styles */
}