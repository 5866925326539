.aboutContainer{
    color: #f3ffff ;
    height: 500px;
    
}
.about-section-grids{
    display: grid;
    grid-template-areas: 
    "home-left home-left home-right home-right";
    width: 100%;
    height: 300px;
    justify-content: center;
    margin-bottom: 30px;
    grid-gap: 20px;
    
}
.about-left-grid{
    grid-area: home-right;
    justify-content: center;
    align-items: center;
    height: 500px;
    width: 500px;
    padding: 20px;
}
.about-right-grid{
    grid-area: home-left;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    width: 500px;
    
}

.about_message_container{
    justify-content: center;
    padding-top: 70px;
    margin-left: 60px;
    overflow-y: auto; /* Enables vertical scrolling if content overflows */
    scrollbar-width: thin;
    scrollbar-color: #f5b6ca #f3ffff;
    padding: 20px; /* Adds padding around the text */
    max-height: 400px; /* Adjust based on your modal size */
}

.about_message_container::-webkit-scrollbar {
    width: 10px; /* Width of the scrollbar */
    height: 16px;
  }
.about_message_container::-webkit-scrollbar-track {
    background: #f3ffff; /* Color of the track */
}
  
.about_message_container::-webkit-scrollbar-thumb {
    background-color: #C38D9E; /* Color of the scrollbar thumb */
    border-radius: 20px; /* Rounded corners on the thumb */
}
.working{
    width: 340px;
    height: auto;
}
.about_message_container p{
    font-size: 18px;
    margin-top: 20px;
    font-weight: 500;
}
.about-top-grid{
    display: none;
}
@media (max-width: 900px) {
    .about-section-grids{
        display: grid;
        grid-template-areas: 
        "home-top home-top"
        "home-left home-left"
        "home-right home-right";
        width: 100%;
        height: 300px;
        justify-content: center;
        margin-bottom: 30px;
        grid-gap: 20px;
        
    }
    .about-top-grid{
        display: flex;
        grid-area: home-top;
        justify-content: center;
        align-items: center;
        width: 500px;
        padding: 20px;
    }
    .about-title-mobile{
        display: block;
        text-align: center;
        margin-top: 20px;
    }
    .about_message{
        display: none;
    }
    .about-left-grid{
        grid-area: home-right;
        justify-content: center;
        align-items: center;
        height: 500px;
        width: 435px;
        margin-top: -20px;
        padding: 20px;
    }
    .about-right-grid{
        grid-area: home-left;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -110px;
        height: 500px;
        width: 500px;
        
    }
    .about-message-container{
        padding-top: 70px;
        margin-left: 60px;
    }
    .about-message-container p{
        font-size: 20px;
        margin-top: 20px;
        font-weight: 500;
    }
}